<template>
  <ui-component-modal
    modalTitle="Delete bundle"
    buttonLabelConfirm="Delete"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteData"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="has-margin-bottom is-size-5 has-text-centered">
        Are you sure you want to remove this bundle?
      </div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="'Yes, I am sure!'"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: { BaseCheckbox },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('bundleStore', ['bundle']),
  },

  created() {},

  methods: {
    ...mapActions('bundleStore', ['deleteBundle']),

    deleteData() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        self
          .deleteBundle(self.bundle)
          .then((response) => {
            if (response.status === 204) {
              // Send message to display toast on screen
              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Bundle deleted!',
              })

              self.onClickCancel()
            }
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
